import React from 'react';
import { Link } from '../components/Link';
import Cal, { getCalApi } from '@calcom/embed-react';
import { useEffect } from 'react';
import SmallFooter from './sections/SmallFooter';
import { getHrefWithSameQueryParams } from '../utilities';

const CalComCalendar = () => {
  useEffect(() => {
    (async function () {
      const cal = await getCalApi();
      cal('ui', {
        theme: 'dark',
        styles: { branding: { brandColor: '#9FE264' } },
        hideEventTypeDetails: true,
        layout: 'month_view',
      });
      cal('on', {
        action: 'bookingSuccessful',
        callback: (e) => {
          window.gtag('event', 'new_booking', {
            event_category: 'booking',
            event_label: 'Book a demo',
          });
        },
      });
    })();
  }, []);
  return (
    <Cal
      calLink="filipenevola/zcloud"
      config={{ layout: 'month_view' }}
      className="w-full overflow-scroll"
    />
  );
};
export const BookDemoPage = () => (
  <>
    <section data-id="workshop" className="mt-16 px-4 lg:px-8 ">
      <div className="mx-auto container">
        <div className="max-w-medium mx-auto flex flex-col items-center gap-8">
          <Link
            to={getHrefWithSameQueryParams('/')}
            className="block max-w-[140px] md:max-w-[190px]"
          >
            <img
              className="block"
              src="https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/zcloud-logo.svg"
              alt="zCloud Logo"
            />
          </Link>

          <h4 className="text-h3 text-light-100 text-center max-w-[850px] pt-12">
            Book a demo
          </h4>

          <CalComCalendar />
        </div>
      </div>
    </section>

    <SmallFooter />
  </>
);
