import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { RoutePaths } from '../../RoutePaths';
import { getHrefWithSameQueryParams } from '../../utilities';
import { Link } from '../../components/Link';

const LinkComponent = ({ children, href, onClick, ...rest }) => {
  return onClick || href?.startsWith('http') ? (
    <Link
      onClick={onClick}
      href={href?.startsWith('http') ? href : undefined}
      {...rest}
    >
      {children}
    </Link>
  ) : (
    <Link to={getHrefWithSameQueryParams(href)} {...rest}>
      {children}
    </Link>
  );
};

const Header = ({ toggleContactUs }) => {
  const [activeMainNavigation, setActiveMainNavigation] = useState('');

  const [expandMobile, setExpandMobile] = useState(false);

  const location = useLocation();
  useEffect(() => {
    setExpandMobile(false);
    setActiveMainNavigation('');
  }, [location.pathname]);

  function isActive({ href, children = [] }) {
    return (
      location.pathname === href ||
      children.find((c) => c.props.href === location.pathname)
    );
  }

  function toggleMobile() {
    setExpandMobile(!expandMobile);
  }

  const links = [
    {
      title: 'Case Studies',
      event: 'website_header_caseStudies_click',
      props: {
        href: RoutePaths.CASE_STUDIES,
      },
      children: [],
    },
    {
      title: 'Contact',
      event: 'website_header_contact_click',
      props: { onClick: toggleContactUs },
      children: [],
    },
    {
      title: 'Docs',
      event: 'website_header_docs_click',
      props: {
        href: getHrefWithSameQueryParams('https://docs.zcloud.ws/docs/intro'),
        target: '_blank',
        rel: 'noreferrer',
      },
      children: [],
    },
    {
      title: 'Login',
      event: 'website_header_logIn_click',
      props: {
        href: getHrefWithSameQueryParams(
          'https://app.zcloud.ws/access?logIn=true'
        ),
      },
      children: [],
    },
    {
      title: 'Register',
      event: 'website_header_register_click',
      props: {
        href: getHrefWithSameQueryParams('https://app.zcloud.ws/access'),
      },
      children: [],
    },
  ];

  return (
    <>
      <section
        data-id="header"
        className="px-4 md:px-8 py-4 md:py-6 md:mt-6 sticky z-10 top-0 bg-neutral-600"
      >
        <div className="container mx-auto flex gap-6 items-center">
          <Link
            event="website_header_logo_click"
            to={getHrefWithSameQueryParams(RoutePaths.HOME)}
            data-id="logo"
            className="block max-w-[140px] md:max-w-[190px]"
          >
            <img
              className="block"
              src="https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/zcloud-logo.svg"
              alt="zCloud Logo"
            />
          </Link>
          <nav className="grow flex justify-end items-center">
            <button
              data-id="mobile-nav-trigger"
              className="lg:hidden"
              onClick={toggleMobile}
            >
              <i className="fa fa-bars text-h5 md:text-h4 text-light-100" />
            </button>
            <ul
              data-id="desktop-nav"
              className="lg:flex flex-row items-center gap-8 hidden"
            >
              {links.map(({ title, event, props, children }) => (
                <li>
                  <LinkComponent
                    event={event}
                    className="text-p font-medium text-light-100 cursor-pointer hover:text-neon"
                    {...props}
                  >
                    {title}
                  </LinkComponent>
                </li>
              ))}
              <li>
                <LinkComponent
                  event="website_header_bookDemo_click"
                  href={getHrefWithSameQueryParams(RoutePaths.BOOK_DEMO)}
                  className="btn-primary whitespace-nowrap"
                >
                  Book a demo
                </LinkComponent>
              </li>
            </ul>
          </nav>
        </div>
      </section>

      <nav
        data-id="mobile-nav"
        className={`fixed top-0 right-0 bottom-0 left-0 bg-black_A92 flex items-center justify-center z-10 ${
          expandMobile ? '' : 'hidden'
        }`}
      >
        <button
          className="absolute top-[20px] right-[20px] text-white p-2"
          onClick={toggleMobile}
        >
          <i className="fa fa-times text-h5"></i>
        </button>
        <ul className="flex flex-col items-center gap-8">
          {links.map(({ title, props, event, children }) => (
            <li key={title} className="ml-1 relative">
              <LinkComponent
                event={event}
                className={`text-h5 text-white hover:text-primary_500 flex gap-2 items-center cursor-pointer ${
                  isActive({ ...props, children })
                    ? 'border-b-2 pb-1 border-primary_500'
                    : ''
                }`}
                {...props}
              >
                {title}
                {children.length > 0 && <i className="fa fa-chevron-down"></i>}
              </LinkComponent>
              {children.length > 0 && (
                <ul
                  className={`mt-4 p-4 flex flex-col items-center gap-8 ${
                    activeMainNavigation === title ? '' : 'hidden'
                  }`}
                >
                  {children.map(({ title, event, props }) => (
                    <LinkComponent
                      event={event}
                      key={title}
                      className={`text-p text-neutral_800 hover:text-primary_500 ${
                        isActive(props)
                          ? 'border-b-2 pb-1 border-primary_500'
                          : ''
                      }`}
                      {...props}
                    >
                      {title}
                    </LinkComponent>
                  ))}
                </ul>
              )}
            </li>
          ))}
          <li className="inline-block ml-4">
            <Link
              event="website_header_signUp_click"
              href={getHrefWithSameQueryParams('https://app.zcloud.ws/access')}
              className="btn-primary py-4 px-6"
            >
              Sign up
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Header;
