import React, { useState } from 'react';
import { Button } from '../components/Button';
import { Link } from '../components/Link';
import { RoutePaths } from '../RoutePaths';
import { simpleanalyticsSendEvent } from '../simpleAnalyticsSendEvent';

export const BringYourOwnProviderPage = () => {
  return (
    <>
      <section
        data-id="bring-your-own-provider"
        className="mt-16 px-4 lg:px-8 bg-hero-cloud bg-no-repeat bg-[center_-120px]"
      >
        <div className="mx-auto container">
          <div className="max-w-medium mx-auto flex flex-col items-center gap-8">
            <h2 className="text-h3 md:text-h2 text-light-100 text-center max-w-[850px] pt-12">
              <span className="text-neon">zCloud "Bring Your Own Provider"</span>: Flexibility and Savings for Your Cloud Infrastructure
            </h2>
            <h5 className="text-h5 text-light-100 text-center">
              Feeling stuck with your current cloud provider? We have a solution to help you save money and gain more flexibility!
            </h5>

            <div className="flex flex-col lg:flex-row gap-8 pt-8">
              <div>
                <h3 className="text-h4 text-light-100 mb-4">How It Works in 4 Simple Steps:</h3>
                <ol className="list-decimal list-inside text-p text-light-500 mb-6">
                  <li className="mb-4">Run your infrastructure anywhere - globally, with any major cloud provider like AWS, Google Cloud, or others. We'll help find the best solution for your apps and databases.</li>
                  <li className="mb-4">We set up everything tailored to your needs, including data migration and app setup.</li>
                  <li className="mb-4">Pay the cloud provider directly, leveraging your special deals, credits, or savings plans. We can even assist in finding the best deals through our cloud partnerships!</li>
                  <li className="mb-4">We charge a monthly fixed fee to keep everything running smoothly with our platform orchestrating it all. This includes our intuitive UI and expert support team.</li>
                </ol>
              </div>
              <div>
                <h3 className="text-h4 text-light-100 mb-4">Benefits of Bring Your Own Provider:</h3>
                <ul className="list-disc list-inside text-p text-light-500 mb-6">
                  <li>Freedom to choose your preferred cloud provider</li>
                  <li>Flexibility in infrastructure location</li>
                  <li>Potential for significant cost savings</li>
                  <li>Expert setup and ongoing support</li>
                  <li>Access to zCloud's powerful management platform</li>
                </ul>
                <p className="text-p text-light-500 mb-6">
                  If you're spending over $2,000 monthly on cloud infrastructure, our "Bring Your Own Provider" solution could lead to substantial savings.
                </p>
              </div>
            </div>

            <div className="bg-neon p-px rounded-8 w-full max-w-[550px]">
              <div className="flex flex-col gap-6 items-center bg-neutral-500 p-6 rounded-8">
                <p className="text-p text-light-500 text-center">
                  Ready to learn more about how "Bring Your Own Provider" can benefit your business?
                </p>
                <Link
                  to={RoutePaths.BOOK_DEMO}
                  className="btn-primary"
                  onClick={() => simpleanalyticsSendEvent('website_byop_book_demo_click')}
                >
                  Book a Demo
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
