import React, { useEffect, useState } from 'react';
import { createEnum, getHrefWithSameQueryParams } from '../utilities';
import { Link } from '../components/Link';
import { simpleanalyticsSendEvent } from '../simpleAnalyticsSendEvent';
import { Button } from '../components/Button';
import CaseStudiesSection from '../pages/sections/CaseStudies';
import { RoutePaths } from '../RoutePaths';
import { useABText } from '../infra/useABText';
import { ListComponent } from '../components/ListComponent';

export const HomePage = ({ toggleContactUs }) => {
  const features = [
    {
      icon: `fab fa-docker`,
      title: `Container based`,
      text: `Select a pre-made, tested Docker image or use your own custom Dockerfile - it's your call. Buildpacks is also available. We offer flexible options, while ensuring a seamless experience from start to finish.`,
    },
    {
      icon: `fab fa-git-alt`,
      title: `Push to Deploy`,
      text: (
        <>
          Connect your Git repository and deploy your code automatically every
          time you push to your selected branch. We have integrations with{' '}
          <Link
            event="website_home_githubAction_click"
            href={getHrefWithSameQueryParams(
              'https://docs.zcloud.ws/docs/cli/github-action'
            )}
            className="text-lime-500"
          >
            GitHub Actions
          </Link>
          ,{' '}
          <Link
            event="website_home_gitlabPipeline_click"
            href={getHrefWithSameQueryParams(
              'https://docs.zcloud.ws/docs/cli/gitlab-pipeline'
            )}
            className="text-lime-500"
          >
            GitLab Pipeline
          </Link>
          ,{' '}
          <Link
            event="website_home_bitbucketPipeline_click"
            href={getHrefWithSameQueryParams(
              'https://docs.zcloud.ws/docs/cli/bitbucket-pipeline'
            )}
            className="text-lime-500"
          >
            Bitbucket Pipeline
          </Link>{' '}
          and{' '}
          <Link
            event="website_home_bitbucketPipeline_click"
            href={getHrefWithSameQueryParams(
              'https://docs.zcloud.ws/docs/cli/azure-devops-pipeline'
            )}
            className="text-lime-500"
          >
            more
          </Link>
          .
        </>
      ),
    },
    {
      icon: `fa fa-database`,
      title: `Database Setup`,
      text: `Choose your preferred database, whether it's MongoDB, MySQL, PostgreSQL or Redis. Our experts will help you with the setup and to keep it running smoothly. You can also use a database provider.`,
    },
    {
      icon: `fa fa-chart-line`,
      title: `One-click Scaling`,
      text: `Need more resources for your app? Just click a button and we'll scale it for you. Autoscaling also available. Scale up or down only takes a few seconds.`,
    },
    {
      icon: `fa fa-rocket`,
      title: `Zero Downtime Deploys`,
      text: `Your app always stays up with our blue/green deployment strategy. Need to revert changes? Redeploy a version or restore configurations with a single-click.`,
    },
    {
      icon: `fa fa-headset`,
      title: `Premium Support`,
      text: `We handle everything from setup to monitoring, including external uptime checks and backups in your account. Plus, you can bring your own server provider.`,
    },
  ];

  const resources = [
    {
      title: '1 zCloud',
      value: 1,
      description: '1 zCloud = 512mb RAM + 0.5z CPU',
    },
    {
      title: '2 zCloud',
      value: 2,
      description: '2 zCloud = 1gb RAM + 1z CPU',
    },
    {
      title: '4 zCloud',
      value: 4,
      description: '4 zCloud = 2gb RAM + 2z CPU',
    },
    {
      title: '8 zCloud',
      value: 8,
      description: '8 zCloud = 4gb RAM + 4z CPU',
    },
  ];

  const plans = [
    {
      title: 'On demand',
      price: 7.5,
      model: (
        <>
          no upfront payment <span className="text-danger-500">*</span>
        </>
      ),
    },
    {
      title: 'Month',
      price: 7.0,
      model: 'upfront for a month',
    },
    {
      title: 'Year',
      price: 6.5,
      model: 'upfront for a year',
    },
  ];

  const faqs = [
    {
      question: `Where do you run your servers?`,
      answer: (
        <div className="flex flex-col gap-2">
          <p>
            We have ready-to-go regions in the US, Finland, and Germany running
            on fully dedicated physical machines.
          </p>
          <p>
            We can also set up a region anywhere in the world with the provider
            of your choice.
          </p>
          <p>
            Check our{' '}
            <Link
              event="website_home_faqRegionsDocs_click"
              href={getHrefWithSameQueryParams(
                'https://docs.zcloud.ws/docs/regions/'
              )}
              className="text-lime-500"
            >
              Regions docs.
            </Link>
          </p>
        </div>
      ),
    },
    {
      question: `How does your support work?`,
      answer: (
        <div className="flex flex-col gap-2">
          <p>
            We have a support team that is available 24/7 to answer any
            questions you might have in the Premium Support offer.
          </p>
          <p>
            For all the other plans we reply in less than 1 hour Monday-Friday,
            9AM-5PM Eastern Time.
          </p>
          <p>
            Check our{' '}
            <Link
              event="website_home_faqSupportDocs_click"
              href={getHrefWithSameQueryParams(
                'https://docs.zcloud.ws/docs/support/'
              )}
              className="text-lime-500"
            >
              Support docs.
            </Link>
          </p>
        </div>
      ),
    },
    {
      question: `Who provides the support?`,
      answer: (
        <div className="flex flex-col gap-2">
          <p>
            Our support team comprises experienced developers who provide
            personalized solutions, not generic answers.
          </p>
          <p>
            We're dedicated to providing genuine assistance until your problem
            is solved.
          </p>
        </div>
      ),
    },
    {
      question: `How is the migration process?`,
      answer: (
        <div className="flex flex-col gap-2">
          <p>We do all your migration, including data. For free.</p>
          <p>
            You can do on your own as well. Migration is usually a breeze,
            especially if you already have Dockerfiles.
          </p>
          <p>
            If you encounter problems, our support channels are ready. You can
            also hire us to handle everything for you.
          </p>
        </div>
      ),
    },
    {
      question: `How are the backups for the databases?`,
      answer: (
        <div className="flex flex-col gap-2">
          <p>
            We run backups at least once a day for all the databases. You can
            download them from our UI.
          </p>
          <p>
            You can also receive backups in your own AWS S3 account if you
            prefer.
          </p>
          <p>
            Check our{' '}
            <Link
              event="website_home_faqSupportDocs_click"
              href={getHrefWithSameQueryParams(
                'https://docs.zcloud.ws/docs/deploy/storage#backups'
              )}
              className="text-lime-500"
            >
              Backups docs.
            </Link>
          </p>
        </div>
      ),
    },
    {
      question: `What about metrics?`,
      answer: (
        <div className="flex flex-col gap-2">
          <p>
            We provide metrics for your app, including CPU, memory, disk and
            network usage. We don't hide anything from you.
          </p>
          <p>
            We also have special dashboards for Databases and Network Request,
            including paths, response times and more.
          </p>
        </div>
      ),
    },
  ];

  const techLogos = [
    {
      tech: `Express`,
      logo: `https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/tech-logo-express.png`,
    },
    {
      tech: `Node`,
      logo: `https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/tech-logo-node.png`,
    },
    {
      tech: `Next`,
      logo: `https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/tech-logo-next.png`,
    },
    {
      tech: `Meteor`,
      logo: `https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/tech-logo-meteor.png`,
    },
    {
      tech: `Vite`,
      logo: `https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/tech-logo-vite.png`,
    },
    {
      tech: `Remix`,
      logo: `https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/tech-logo-remix.png`,
    },
    {
      tech: `Vue`,
      logo: `https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/tech-logo-vue.png`,
    },
  ];

  const otherTechLogos = [
    {
      tech: `Rails`,
      logo: `https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/tech-logo-rails.png`,
    },
    {
      tech: `Django`,
      logo: `https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/tech-logo-django.png`,
    },
    {
      tech: `Quarkus`,
      logo: `https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/tech-logo-quarkus.png`,
    },
    {
      tech: `Spring`,
      logo: `https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/tech-logo-spring.png`,
    },
    {
      tech: `Laravel`,
      logo: `https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/tech-logo-laravel.png`,
    },
    {
      tech: `WordPress`,
      logo: `https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/tech-logo-wordpress.png`,
    },
    {
      tech: `Anything`,
      logo: `https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/tech-logo-anything-v2.png`,
    },
  ];

  const [resource, setResource] = useState(resources[0]);

  const urlParams = new URLSearchParams(window.location.search);
  const lang = urlParams.get('lang') || navigator.language;
  const isPortuguese = lang?.toLowerCase().includes('pt');

  const scrollToElement = (elementId) => () => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  };

  const HomeMainTitleContents = createEnum({
    MADE_EASY: {
      text: 'Made Easy',
      searchParamValue: 'made-easy',
      event: 'see_content_header',
      eventCategory: 'content',
      eventLabel: 'Made Easy',
      eventValue: 1,
    },
    PROFITABLE_COMPANIES: {
      text: 'For Profit-focused',
      searchParamValue: 'profit-focused',
      event: 'see_content_header',
      eventCategory: 'content',
      eventLabel: 'For Profit-focused',
      eventValue: 2,
    },
    CONCIERGE: {
      text: 'With Concierge',
      searchParamValue: 'with-concierge',
      event: 'see_content_header',
      eventCategory: 'content',
      eventLabel: 'With Concierge',
      eventValue: 3,
    },
  });

  const mainTitle = useABText({
    enumDefinition: HomeMainTitleContents,
    name: 'homeMainTitle',
  });

  const HomeHeadlineContents = createEnum({
    WE_HANDLE: {
      text: <>We handle Migration, Configuration, and Monitoring for you.</>,
      searchParamValue: 'we-handle',
      event: 'see_content_headline',
      eventCategory: 'content',
      eventLabel: 'We handle',
      eventValue: 4,
    },
    WE_HUMANS: {
      text: (
        <>
          We (<span className="text-light-500">humans</span>) handle Migration,
          Configuration, and Monitoring for you.
        </>
      ),
      searchParamValue: 'we-humans',
      event: 'see_content_headline',
      eventCategory: 'content',
      eventLabel: 'We humans',
      eventValue: 5,
    },
    EXPERIENCE: {
      text: (
        <>
          Experience the flexibility and ease of Cloud hosting with{' '}
          <span className="text-light-500">amazing&nbsp;support</span>.
        </>
      ),
      searchParamValue: 'experience',
      event: 'see_content_headline',
      eventCategory: 'content',
      eventLabel: 'Experience',
      eventValue: 6,
    },
  });

  const headline = useABText({
    enumDefinition: HomeHeadlineContents,
    name: 'homeHeadline',
  });

  return (
    <div className="bg-hero-cloud-large bg-[center_-60px] bg-no-repeat">
      <section data-id="hero" className="px-4 lg:px-8">
        <div className="container mx-auto flex flex-col items-center gap-8 pt-32">
          <h1 className="text-h3 text-light-100 sm:text-h2 md:text-h1 text-center">
            Cloud. <span className="text-neon">{mainTitle}</span>.
          </h1>
          <h4 className="text-h5 text-light-700 md:text-h4 max-w-[900px] text-center">
            {headline}
          </h4>
          <div className="flex flex-col items-center gap-8 sm:flex-row sm:gap-4">
            <Link
              event="website_home_getStartedHero_click"
              href={getHrefWithSameQueryParams(RoutePaths.BOOK_DEMO)}
              className="btn-primary"
            >
              Book a demo
            </Link>
            <p className="text-p">
              <span className="mr-2">or</span>
              <Link
                event="website_home_watchDemoHero_click"
                href="https://www.youtube.com/watch?v=PxIsmmdx8ac"
                target="_blank"
                rel="noreferrer"
                className="text-p text-lime-500"
              >
                See it in action
              </Link>
            </p>
          </div>

          <div
            className={`bg-neon rounded-8 mt-14 max-w-[650px] cursor-pointer p-px ${
              isPortuguese ? 'relative' : 'hidden'
            }`}
          >
            <span className="bg-neon rounded-4 text-small absolute -left-[12px] -top-[10px] px-1 py-0.5 font-semibold text-neutral-500 md:-left-[30px]">
              NEW
            </span>
            <div className="rounded-8 xsm:flex-row flex flex-col gap-4 bg-neutral-500 p-3">
              <div className="xsm:max-w-[190px] relative aspect-video w-full cursor-pointer">
                <img
                  src="https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/cs-poli-video-thumb.jpg"
                  alt="Video thumb"
                  className="rounded-8 h-full w-full object-cover"
                />
                <i className="fas fa-play-circle absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-4xl text-white" />
              </div>
              <div>
                <p className="text-p flex items-center gap-2">
                  <img
                    className=""
                    src="https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/br-flag.png"
                    alt="BR"
                  />
                  <span className="text-small">Conteúdo em Português</span>
                </p>
                <p className="text-p text-light-500 py-2">
                  A conversation with Poli about zCloud. Migration, customer
                  support, monitoring, and more.
                </p>
                <Link
                  event="website_home_goToVideo_click"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToElement('poli-video')();
                  }}
                  className="text-p flex flex-nowrap items-center gap-1 text-lime-600"
                >
                  <span>Go to video</span>
                  <i className="fa fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>

          <div className="relative hidden w-full max-w-[850px] pt-8">
            {/*
                Use this video for test:
                https://videos.ctfassets.net/xny2w179f4ki/3qp0qLfCQJWYrL4jN44VVm/ece9ffe7b105f763a9b5d12ab16f603c/Homepage_hero_video.mp4#t=0.001
            */}
            <video
              className="rounded-8 w-full bg-black"
              src=""
              autoPlay
              loop
              muted
            ></video>
          </div>
        </div>
      </section>

      <section data-id="easy-setup" className="mt-32 px-4 lg:px-8">
        <div className="container mx-auto flex flex-col items-center">
          <h2 className="text-h3 text-light-100 md:text-h2 text-center">
            Effortless setup process
          </h2>
          <h5 className="text-h5 text-light-700 mt-6 max-w-[800px] text-center">
            The choice is yours: a ready-to-go region or your own provider
          </h5>

          <div className="w-full max-w-[800px]">
            <h5 className="text-h5 font-regular text-light-500 mb-2 mt-16">
              Simple 3-step deploy
            </h5>
            <p className="text-p">
              Connect to GitHub, choose a branch, push to deploy. That simple.
            </p>
            <div
              data-id="deploy-illustration"
              className="rounded-8 text-p text-light-500 xsm:flex-row xsm:justify-around mt-6 flex flex-col items-center gap-3 bg-neutral-500 px-4 pb-4 pt-4 md:items-start md:gap-0 md:bg-transparent"
            >
              <div className="flex flex-col items-start md:pt-4">
                <img
                  src="https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/deploy-left.svg"
                  alt=""
                  className="hidden md:block"
                />
                <img
                  src="https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/deploy-git.png"
                  alt=""
                  className="block md:hidden"
                />
                <span className="-ml-1 block w-[80px] text-center">
                  Connect to GitHub
                </span>
              </div>

              <div className="xsm:-mt-6 xsm:h-[2px] xsm:w-auto h-[40px] w-[2px] max-w-[100px] grow bg-lime-500 md:hidden" />

              <ul className="flex flex-col gap-2 md:grow">
                <li className="rounded-4 text-light-700 bg-neutral-400 px-4 py-2 text-center md:bg-neutral-500">
                  feature/agenda
                </li>
                <li className="bg-neon rounded-4 px-4 py-2 text-center text-neutral-600">
                  feature/calendar
                </li>
                <li className="rounded-4 text-light-700 bg-neutral-400 px-4 py-2 text-center md:bg-neutral-500">
                  feature/meetings
                </li>
                <li className="text-center">Select a branch</li>
              </ul>

              <div className="xsm:-mt-6 xsm:h-[2px] xsm:w-auto h-[40px] w-[2px] max-w-[100px] grow bg-teal-500 md:hidden" />

              <div className="flex flex-col items-end md:pt-4">
                <img
                  src="https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/deploy-right.svg"
                  alt=""
                  className="hidden md:block"
                />
                <img
                  src="https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/deploy-push.png"
                  alt=""
                  className="block md:hidden"
                />
                <span className="-mr-1 block w-[80px] text-center">
                  Push to deploy
                </span>
              </div>
            </div>
            <div className="mt-6 text-center">
              <span className="text-p">
                You can deploy using our{' '}
                <Link
                  event="website_home_cli_click"
                  href={getHrefWithSameQueryParams(
                    'https://docs.zcloud.ws/docs/category/cli'
                  )}
                  className="text-lime-500"
                >
                  CLI
                </Link>{' '}
                as well.
              </span>
            </div>

            <h5 className="text-h5 font-regular text-light-500 mb-2 mt-16">
              Docker presets
            </h5>
            <p className="text-p pb-6">
              Docker configuration gets detected based on your project files and
              the preset loaded automatically. We currently have presets for
              these technologies but plan to add many more:
            </p>
            <ul
              data-id="tech-logos"
              className="xsm:grid-cols-4 grid grid-cols-2 gap-12 lg:grid-cols-7"
            >
              {techLogos.map(({ tech, logo }) => (
                <li className="flex flex-col items-center gap-2">
                  <img src={logo} alt={tech} />
                  <span className="text-p text-light-500">{tech}</span>
                </li>
              ))}
            </ul>

            <h5 className="text-h5 font-regular text-light-500 mb-2 mt-16">
              Infinite compatibility
            </h5>
            <p className="text-p pb-6">
              Use any technology that’s compatible with Docker. Javascript,
              Java, PHP, Ruby, Python and many more.
            </p>
            <ul
              data-id="tech-logos"
              className="xsm:grid-cols-4 grid grid-cols-2 gap-12 lg:grid-cols-7"
            >
              {otherTechLogos.map(({ tech, logo }) => (
                <li className="flex flex-col items-center gap-2">
                  <img src={logo} alt={tech} />
                  <span className="text-p text-light-500">{tech}</span>
                </li>
              ))}
            </ul>

            <h5 className="text-h5 font-regular text-light-500 mb-2 mt-16">
              Complete control
            </h5>
            <p className="text-p pb-2">
              Take control of your configuration. Create your own Dockerfile or
              start with one of our templates. There’s no limit to what you can
              do.
            </p>
          </div>
        </div>
      </section>

      <section data-id="features" className="mt-24 px-4 lg:px-8">
        <div className="container mx-auto flex flex-col items-center">
          <h2 className="text-h3 text-light-100 md:text-h2 text-center">
            Powerful features
          </h2>
          <h5 className="text-h5 text-light-700 mt-6 max-w-[800px] text-center">
            Designed by developers, for developers: a comprehensive set of tools
            to meet your cloud hosting needs.
          </h5>

          <ul className="mt-16 grid grid-cols-1 gap-x-8 gap-y-12 sm:grid-cols-2 lg:grid-cols-3">
            {features.map(({ icon, title, text }) => (
              <li className="">
                <i className={`${icon} text-h3 mb-2 text-lime-500`} />
                <h5 className="text-h5 text-light-500 mb-2 font-normal">
                  {title}
                </h5>
                <p className="text-p text-light-700">{text}</p>
              </li>
            ))}
          </ul>
        </div>
      </section>

      <section data-id="support" className="mt-24 px-4 lg:px-8">
        <div className="bg-neon rounded-8 xsm:pb-96 lg:pr-138 container relative mx-auto flex min-h-[420px] flex-col items-center justify-center gap-2 overflow-hidden px-8 py-8 pb-0 text-center text-neutral-600 md:items-start md:pb-8 md:pr-96 md:text-left lg:px-16">
          <h4 className="text-h5 md:text-h4">
            <span className="font-regular">
              Amazing support.
              <br />
              Less than 1h response time.
            </span>
          </h4>
          <p className="text-p">
            Our support team is filled with DevOps and app development experts,
            ready to help you tackle any problems in record breaking time. We've
            got you covered with practical solutions and reliable guidance. We
            work Monday-Friday, 9AM-5PM Eastern Time.
          </p>
          <Button
            onClick={toggleContactUs}
            className="btn-secondary mt-2"
            event="website_home_contactSupport_click"
          >
            Contact support
          </Button>

          <img
            src="https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/support-people.png"
            alt="zCloud support people"
            className=" xsm:absolute xsm:bottom-0 xsm:right-0 xsm:mt-0 md:-right-[144px] lg:right-0"
          />
        </div>
      </section>

      <section id="pricing" data-id="pricing" className="mt-24 px-4 lg:px-8">
        <div className="container mx-auto flex flex-col items-center">
          <h2 className="text-h3 text-light-100 md:text-h2 text-center">
            Fixed resource-based pricing
          </h2>
          <h5 className="text-h5 text-light-700 mb-8 mt-6 max-w-[900px] text-center">
            Our plans provide excellent value with no unexpected costs. We don’t
            charge for network traffic and we’ll migrate your database and apps
            to zCloud for free.
          </h5>

          <div className="flex flex-wrap justify-center gap-4">
            <h6 className="rounded-8 text-h6 text-light-500 border border-lime-500 px-4 py-2 text-center font-light">
              ZERO network bandwidth costs
            </h6>
            <h6 className="rounded-8 text-h6 text-light-500 border border-lime-500 px-4 py-2 text-center font-light">
              FREE white-glove migration
            </h6>
          </div>

          <ul className="mt-12 flex flex-wrap justify-center gap-4">
            {resources.map((r) => {
              const { title, value } = r;
              const resourceClass =
                value === resource.value
                  ? 'bg-neutral-200 text-light-500 border-2 border-transparent'
                  : 'text-light-100 border-2 border-neutral-200 hover:border-neutral-100';
              return (
                <li
                  className={`px-4 py-2 ${resourceClass} rounded-8 cursor-pointer`}
                  onClick={() => {
                    setResource(r);
                    simpleanalyticsSendEvent(
                      `website_home_${value}zClouds_click`
                    );
                  }}
                >
                  {title}
                </li>
              );
            })}
          </ul>

          <div className="w-full max-w-[450px]">
            <p className="text-p text-light-700 mt-4 text-center">
              {resource.description}
            </p>
          </div>

          <ul className="mt-6 grid w-full max-w-[1024px] grid-cols-1 gap-x-6 gap-y-6 lg:grid-cols-3">
            {plans.map(({ title, price, model }) => (
              <li className="rounded-8 flex shrink grow flex-col items-center justify-center gap-2 bg-neutral-500 py-4 lg:h-[240px]">
                <h6 className="text-h6 text-light-700">{title}</h6>
                <h3 className="text-h3 text-light-100">
                  ${price * resource.value}
                  <span className="text-h4">/mo</span>
                </h3>
                <h6 className="text-p text-light-700">{model}</h6>
              </li>
            ))}
          </ul>

          <div className="mt-6 text-center">
            <h6 className="text-h6 text-light-500">
              $15 use-anyway-you-want sign-up bonus
            </h6>
            <p className="text-p text-light-700 mt-1">
              For example, run one app with 2 zClouds for 30 days or 4 zClouds
              for 15 days.
            </p>
            <p className="text-p text-light-500 mt-6">
              <span className="text-danger-500">*</span> No credit card required
              to start. Prices for standard price regions, check our{' '}
              <Link
                event="website_home_checkPricing_click"
                href={getHrefWithSameQueryParams(
                  'https://docs.zcloud.ws/docs/billing/pricing'
                )}
                className="text-lime-500"
              >
                Pricing docs
              </Link>
              .
            </p>
          </div>
        </div>
      </section>

      <section id="regions" data-id="regions" className="mt-24 px-4 lg:px-8">
        <div className="container mx-auto flex flex-col items-center">
          <h2 className="text-h3 text-light-100 md:text-h2 text-center">
            Regions and Providers
          </h2>
          <h5 className="text-h5 text-light-700 mb-8 mt-6 max-w-[900px] text-center">
            Use our <span className="text-light-500">standard price ready-to-go</span> regions or set up <span className="text-light-500">your own region</span>
          </h5>

          <ListComponent
              items={[
                <>
                  Instantly deploy in our ready-to-use regions or ...
                </>,
                <>
                  Choose anywhere in the World and the Cloud Provider you want.
                </>,
                <>
                  Run in your own <strong>cloud accounts</strong> with your credits and security settings, if you prefer.
                </>,
              ]}
          />

          <Link
            to={RoutePaths.BRING_YOUR_OWN_PROVIDER}
            className="mt-6 text-lime-500 hover:underline"
          >
            Learn more about bringing your own provider
          </Link>
        </div>
      </section>


      <section id="about-us" data-id="about-us" className="mt-24 px-4 lg:px-8">
        <div className="container mx-auto flex flex-col items-center">
          <h2 className="text-h3 text-light-100 md:text-h2 text-center">
            What's special about us?
          </h2>
          <h5 className="text-h5 text-light-700 mb-8 mt-6 max-w-[900px] text-center">
            Built to run
            <span className="text-light-500"> independently </span>
            of investors. Dedicated to our clients'
            <span className="text-light-500"> satisfaction </span>through
            amazing support and
            <span className="text-light-500"> honest </span>
            relationships
          </h5>

            <ul className="mt-16 flex w-full max-w-[1024px] flex-col items-center gap-8 md:justify-between lg:flex-row lg:gap-0">
              <li className="flex items-center gap-2">
                <h2 className="text-h3 md:text-h2 text-lime-500">
                  +10<span className="text-h4">yrs</span>
                </h2>
                <h5 className="text-h5 text-light-500 leading-none">
                  experience in
                  <br />
                  hosting services
                </h5>
              </li>
              <li className="flex items-center gap-2">
                <h2 className="text-h3 md:text-h2 text-lime-500">
                  {/*  manager 1371 + eu1 229 + eu2 205 + us2 318 + monitor 29} */}
                  +2150
                </h2>
                <h5 className="text-h5 text-light-500 leading-none">
                  running
                  <br />
                  containers
                </h5>
              </li>
              <li className="flex items-center gap-2">
                <h2 className="text-h3 md:text-h2 text-lime-500">
                  0<span className="text-h4">%</span>
                </h2>
                <h5 className="text-h5 text-light-500 leading-none">
                  reliance on big
                  <br />
                  cloud services
                </h5>
              </li>
            </ul>
        </div>
      </section>

      <section data-id="testimonials" className="mt-24 px-4 lg:px-8">
        <div className="container mx-auto flex flex-col items-center">
          <h2 className="text-h3 text-light-100 md:text-h2 pb-16 text-center">
            Case studies
          </h2>
          <CaseStudiesSection page="home" />
        </div>
      </section>

      <section data-id="faq" className="mt-24 px-4 lg:px-8">
        <div className="container mx-auto flex flex-col items-center">
          <h2 className="text-h3 text-light-100 md:text-h2 text-center">
            Frequently asked questions
          </h2>

          <ul className="mt-16 grid max-w-[1024px] grid-cols-1 gap-x-8 gap-y-16 md:grid-cols-2">
            {faqs.map(({ question, answer }) => (
              <li className="">
                <h6 className="text-h6 font-regular text-light-500 mb-2">
                  {question}
                </h6>
                <p className="text-p">{answer}</p>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </div>
  );
};
