import { RoutePaths } from '../../RoutePaths';
import { getHrefWithSameQueryParams } from '../../utilities';
import { Link } from '../../components/Link';
import React from 'react';

const SmallFooter = () => {
  return (
    <>
      <footer className="flex grow flex-col items-center gap-2 pt-8 pb-16">
        <p className="text-small grow">
          © 2024 zCloud by <Link href="https://quave.dev">quave</Link>. All
          right reserved.
        </p>
        <ul className="flex items-center gap-2">
          <li>
            <Link
              event="website_footer_termsAndConditions_click"
              href={getHrefWithSameQueryParams(RoutePaths.TERMS_AND_CONDITIONS)}
              target="_blank"
              rel="noreferrer"
              className="text-small text-light-700 hover:text-lime-500"
            >
              Terms & Conditions
            </Link>
          </li>
          <li>|</li>
          <li>
            <Link
              event="website_footer_privacyPolicy_click"
              href={getHrefWithSameQueryParams(RoutePaths.PRIVACY_POLICY)}
              target="_blank"
              rel="noreferrer"
              className="text-small text-light-700 hover:text-lime-500"
            >
              Privacy Policy
            </Link>
          </li>
        </ul>
      </footer>
    </>
  );
};

export default SmallFooter;
