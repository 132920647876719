import { Link } from '../../components/Link';
import { getHrefWithSameQueryParams } from '../../utilities';
import { RoutePaths } from '../../RoutePaths';
export const CSDunderonPage = () => (
  <>
    <section className="mt-8 lg:mt-16 px-4 lg:px-8">
      <div className="mx-auto container">
        <div className="mx-auto max-w-medium flex flex-col lg:flex-row gap-8">
          <div className="grow-0 shrink-0 lg:basis-[150px] pt-2">
            <Link
              event="website_csDunderon_back_click"
              to={getHrefWithSameQueryParams(RoutePaths.CASE_STUDIES)}
              className="text-p text-lime-600"
            >
              <i className="fa fa-arrow-left"></i> <span>All case studies</span>
            </Link>
          </div>
          <div className="flex flex-col gap-6">
            <h4 className="text-h4 text-light-500">
              Unifying Operations and Cutting Hosting Costs for Dunderon AB
            </h4>
            <h6 className="text-h6 font-light text-light-500">
              "Congratulations! zCloud works brilliantly! Migrated from AWS and
              others, now all services are under one roof with top-notch
              support! Thanks guys!"
            </h6>
            <div className="flex gap-2 items-center">
              <img
                src="https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/cs-dunderon-photo.png"
                alt="Robert Kviberg"
              />
              <p className="flex flex-col">
                <small className="text-small text-light-700">
                  Robert Kviberg
                </small>
                <small className="text-small text-light-800">Founder</small>
              </p>
            </div>
            <p className="text-p">
              Dunderon AB faced management challenges with their four Node.js
              apps running on AWS over TCP and Meteor apps on Meteor Cloud
              (Galaxy) incurring high hosting costs. Seeking a more unified and
              cost-effective solution, they approached zCloud for assistance,
              including to migrate their database as well.
            </p>
            <p className="text-p">
              We successfully migrated all their apps and databases to zCloud's
              optimized hosting platform. By consolidating their operations
              under one roof, Dunderon gained better control and streamlined
              efficiency for their entire system. Additionally, the migration
              resulted in significant cost savings on hosting expenses. With
              zCloud, Dunderon now enjoys a cohesive and cost-efficient hosting
              solution, empowering them to focus on their core business while
              providing a seamless experience for their users.
            </p>
          </div>
          <aside className="grow-0 shrink-0 lg:basis-[250px]">
            <div className="bg-neutral-500 p-4 rounded-8 flex flex-col gap-2 items-start">
              <img
                src="https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/cs-dunderon-logo.png"
                alt="Dunderon"
                className="mb-2"
              />
              <p className="text-p font-medium text-light-500">Dunderon</p>
              <p className="text-p text-light-700">
                Self-learning, collaborative and dynamic regulation software for
                heating, hot water and ventilation.
              </p>
              <p className="text-p text-light-700">
                <span className="text-light-500">150+</span> properties
                <br />
                <span className="text-light-500">5,000+</span> apartments
                <br />
                <span className="text-light-500">15,000+</span> sensors
              </p>
              <Link
                event="website_csDunderon_website_click"
                className="text-p text-lime-600"
                href="https://dunderon.se/"
                target="_blank"
              >
                dunderon.se
              </Link>
            </div>
          </aside>
        </div>
      </div>
    </section>
  </>
);
