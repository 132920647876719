import { getHrefWithSameQueryParams } from '../utilities';
import { Link } from '../components/Link';
import { RoutePaths } from '../RoutePaths';
import React, { useState } from 'react';
import { Button } from '../components/Button';
import { subscribeToLemeno } from '../infra/lemenoServices';
import { simpleanalyticsSendEvent } from '../simpleAnalyticsSendEvent';
import SmallFooter from './sections/SmallFooter';

export const WorkshopPage = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [sending, setSending] = useState(false);
  const [isSignedUp, setIsSignedUp] = useState(false);
  const signUp = () => {
    setError('');
    setIsSignedUp(false);
    setSending(true);
    subscribeToLemeno({ email, tags: ['workshop'] })
      .then((r) => {
        setIsSignedUp(true);
        setSending(false);
      })
      .catch((e) => {
        setIsSignedUp(false);
        setSending(false);
        setError(
          `Erro inesperado no momento da inscrição, por favor mande um email para support@zcloud.ws!`
        );
      });
  };
  return (
    <>
      <section
        data-id="workshop"
        className="mt-16 px-4 lg:px-8 bg-hero-cloud bg-no-repeat bg-[center_-120px]"
      >
        <div className="mx-auto container">
          <div className="max-w-medium mx-auto flex flex-col items-center gap-8">
            <div className="block max-w-[140px] md:max-w-[190px]">
              <img
                className="block"
                src="https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/zcloud-logo.svg"
                alt="zCloud Logo"
              />
            </div>

            <h2 className="text-h3 md:text-h2 text-light-100 text-center max-w-[850px] pt-12">
              <span className="text-neon">Workshop Gratuito</span>: Turbinando a
              Eficiência dos seus Apps no Cloud
            </h2>
            <h5 className="text-h5 text-light-100 text-center">
              17 de Agosto (quinta-feira), começando às 18:30h (1h de duração),
              com:
            </h5>

            <div className="flex flex-col sm:flex-row gap-8">
              <div className="flex gap-2 items-center">
                <img
                  className="w-12 h-12 object-cover rounded-full"
                  src="https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/members-filipe.jpeg"
                  alt="Filipe Névola"
                />
                <p className="flex flex-col">
                  <small className="text-small text-light-500">
                    Filipe Névola
                  </small>
                  <small className="text-small text-light-700">
                    zCloud CEO
                  </small>
                </p>
              </div>
              <div className="flex gap-2 items-center">
                <img
                  className="w-12 h-12 object-cover rounded-full"
                  src="https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/members-edimar.jpeg"
                  alt="Edimar Cardoso"
                />
                <p className="flex flex-col">
                  <small className="text-small text-light-500">
                    Edimar Cardoso
                  </small>
                  <small className="text-small text-light-700">
                    zCloud Principal DevOps
                  </small>
                </p>
              </div>
            </div>

            <div className="bg-neon p-px rounded-8 w-full max-w-[550px]">
              {!isSignedUp && (
                <div className="flex flex-col sm:flex-row gap-6 items-center bg-neutral-500 p-6 rounded-8">
                  <input
                    name="name"
                    type="text"
                    placeholder="Informe seu email"
                    className="rounded-4 px-4 py-3 bg-neutral-400 text-p text-light-500 placeholder:text-light-700 w-[300px] sm:w-full"
                    value={email}
                    onKeyDown={(e) => {
                      if (e.key !== 'Enter') {
                        return;
                      }

                      signUp();
                      simpleanalyticsSendEvent(
                        'website_workshop_subscribe_enter'
                      );
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Button
                    className="btn-primary grow-0 shrink-0"
                    event="website_workshop_subscribe_click"
                    onClick={signUp}
                    disabled={sending}
                  >
                    {sending ? 'Enviando...' : 'Inscrever-se'}
                  </Button>
                </div>
              )}
              {isSignedUp && (
                <div className="flex flex-col gap-6 items-center bg-neutral-500 p-6 rounded-8">
                  <p className="text-p text-light-500">
                    Inscrição realizada com sucesso! Obrigado. Nós enviaremos
                    mais detalhes no seu email em breve, verifique seus spams
                    caso não receba em sua caixa de entrada ou entre em contato
                    através do email support@zcloud.ws.
                  </p>
                  <p className="text-p text-light-700">
                    Responda 5 perguntas sobre seu uso de Cloud{' '}
                    <Link
                      event="website_workshop_form_click"
                      className="text-lime-600"
                      href={getHrefWithSameQueryParams(
                        'https://forms.gle/zUqtLUEMVLsaPGTd7'
                      )}
                      target="_blank"
                    >
                      aqui
                    </Link>{' '}
                    (leva menos de 3 mins) e moldaremos o workshop baseado em
                    suas respostas.
                  </p>
                </div>
              )}
            </div>
            {error && (
              <div className="rounded-4 px-4 py-2 bg-danger-700A10 border border-danger-600 text-danger-200 text-p">
                {error}
              </div>
            )}

            <div className="flex flex-col lg:flex-row gap-8 pt-8">
              <div>
                <p className="text-p text-light-500 mb-6">
                  É um grande desafio garantir uma boa performance no ambiente
                  de Cloud. São tantas ferramentas e opções que muitas vezes nos
                  sentimos perdidos e não sabemos o que é melhor para cada
                  situação. Neste rápido workshop, vamos falar sobre as melhores
                  práticas com Docker para otimizar sua aplicação. Abordaremos
                  técnicas para criar imagens Docker eficientes e reduzir o
                  tempo de inicialização dos contêineres.
                </p>

                <p className="text-p text-light-500 mb-6">
                  Também vamos conversar sobre como escalar e monitorar as
                  aplicações. A escalabilidade é essencial para lidar com picos
                  de tráfego e garantir que sua aplicação possa crescer conforme
                  a demanda. Exploraremos como utilizar o Kubernetes para
                  escalonar automaticamente os contêineres com base nas métricas
                  de carga e desempenho.
                </p>

                <p className="text-p text-light-500">
                  Além da aplicação em si, também abordaremos diferenças entre
                  utilizar servidores bare-metal e máquinas virtuais. Bare-metal
                  é quando utilizamos as máquinas diretamente, aproveitando o
                  acesso direto aos recursos físicos, enquanto as máquinas
                  virtuais oferecem uma camada adicional de virtualização.
                </p>
              </div>
              <div>
                <p className="text-p text-light-500 mb-6">
                  Hoje, a maioria dos produtos dos grandes provedores de Cloud
                  oferece apenas máquinas virtuais. Vamos te explicar por que
                  essa escolha pode limitar o desempenho da sua aplicação e como
                  mitigar os impactos por meio de ajustes e otimizações
                  específicas.
                </p>

                <p className="text-p text-light-500 mb-6">
                  E de bônus, vamos te apresentar um pouco do zCloud, que é uma
                  plataforma que implementa tudo que será abordado nesse
                  workshop e que você consegue usar apenas definindo o seu
                  Dockerfile. O zCloud é uma solução completa para implementar e
                  gerenciar aplicações Docker de maneira eficiente e escalável.
                  Ele automatiza muitas das tarefas complexas relacionadas ao
                  uso do contêineres em um ambiente de nuvem, permitindo que
                  você se concentre no desenvolvimento da sua aplicação.
                </p>

                <p className="text-p text-light-500">
                  Após esse workshop será o momento de você praticar. Não tem
                  como absorver tudo isso sem colocar a mão na massa. Com sua
                  própria experiência aí chegará a hora de decidir se quer
                  manter essa infraestrutura por conta própria ou se prefere
                  delegar para uma solução completa como o zCloud.
                </p>
              </div>
            </div>

            <div className="max-w-small text-center pt-36">
              <p className="text-p text-light-700 mb-6">
                O Workshop será online e ao vivo. Detalhes serão enviados com
                antecedência pela email.
              </p>
              <p className="text-p text-light-700 mb-6">
                zCloud é uma plataforma de Cloud que remove a complexidade e
                facilita suas entregas. Pensado para ser versátil. Arquitetada
                para qualquer linguagem de programação. Executa apps e bancos de
                dados. Oferece suporte de experts e preço competitivo.
              </p>
              <Link
                event="website_workshop_learnMore_click"
                href={getHrefWithSameQueryParams(RoutePaths.HOME)}
                className="btn-outline"
              >
                Saiba mais <i className="fa fa-arrow-right" />
              </Link>
            </div>

            <SmallFooter />
          </div>
        </div>
      </section>
    </>
  );
};
