export const getHrefWithSameQueryParams = (url) => {
  const search = window.location.search;
  return `${url}${url.includes('?') ? search.replace('?', '&') : search}`;
};

export const createEnum = (obj, options = {}) =>
  Object.entries(obj).reduce(
    (acc, [key, value], index) => ({
      ...acc,
      [key]: {
        ...options.defaultFields,
        name: key,
        index,
        ...value,
      },
    }),
    {}
  );
