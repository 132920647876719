import { getHrefWithSameQueryParams } from '../utilities';
import { Link } from '../components/Link';

export const PrivacyPolicyPage = () => (
  <>
    <section data-id="privacy-policy" className="px-4 lg:px-8 mb-24">
      <div className="container lg:max-w-small mx-auto text-light-700 article">
        <h3 className="text-h3 md:text-h2 text-light-100 text-center mt-8 mb-16">
          Privacy Policy
        </h3>

        <p>
          <strong>Last updated April 14, 2023</strong>
        </p>

        <p>
          This privacy notice forzcloud.ws(&quot;
          <strong>Company</strong>,&quot; &quot;<strong>we</strong>,&quot;
          &quot;<strong>us</strong>,&quot; or &quot;<strong>our</strong>
          &quot;), describes how and why we might collect, store, use, and/or
          share (&quot;<strong>process</strong>&quot;) your information when you
          use our services (&quot;
          <strong>Services</strong>&quot;), such as when you:
        </p>
        <ul>
          <li>
            Visit our website at{' '}
            <Link
              href={getHrefWithSameQueryParams('https://zcloud.ws/')}
              rel="noreferrer"
              target="_blank"
              className="text-lime-600"
            >
              https://zcloud.ws
            </Link>
            , or any website of ours that links to this privacy notice
          </li>
          <li>
            Engage with us in other related ways, including any sales,
            marketing, or events
          </li>
        </ul>
        <p>
          <strong>Questions or concerns?</strong>
          <br />
          Reading this privacy notice will help you understand your privacy
          rights and choices. If you do not agree with our policies and
          practices, please do not use our Services. If you still have any
          questions or concerns, please contact us atsupport@zcloud.ws.
        </p>

        <h5>Summary of key points</h5>

        <p>
          <strong>What personal information do we process?</strong>
          <br />
          When you visit, use, or navigate our Services, we may process personal
          information depending on how you interact with zCloud.ws and the
          Services, the choices you make, and the products and features you use.
          Learn more about
          <Link
            href={getHrefWithSameQueryParams(
              'https://zcloud.ws/privacy-policy'
            )}
            className="text-lime-600"
          >
            personal information you disclose to us
          </Link>
          .
        </p>

        <p>
          <strong>Do we process any sensitive personal information?</strong>
          <br />
          We do not process sensitive personal information.
        </p>

        <p>
          <strong>Do we receive any information from third parties?</strong>
          <br />
          We do not receive any information from third parties.
        </p>

        <p>
          <strong>How do we process your information?</strong>
          <br />
          We process your information to provide, improve, and administer our
          Services, communicate with you, for security and fraud prevention, and
          to comply with law. We may also process your information for other
          purposes with your consent. We process your information only when we
          have a valid legal reason to do so. Learn more about
          <Link
            href={getHrefWithSameQueryParams(
              'https://zcloud.ws/privacy-policy'
            )}
            className="text-lime-600"
          >
            how we process your information
          </Link>
          .
        </p>

        <p>
          <strong>
            In what situations and with which types of parties do we share
            personal information?
          </strong>
          <br />
          We may share information in specific situations and with specific
          categories of third parties. Learn more about
          <Link
            href={getHrefWithSameQueryParams(
              'https://zcloud.ws/privacy-policy'
            )}
          >
            when and with whom we share your personal information
          </Link>
          .
        </p>

        <p>
          <strong>How do we keep your information safe?</strong>
          <br />
          We have organizational and technical processes and procedures in place
          to protect your personal information. However, no electronic
          transmission over the internet or information storage technology can
          be guaranteed to be 100% secure, so we cannot promise or guarantee
          that hackers, cybercriminals, or other unauthorized third parties will
          not be able to defeat our security and improperly collect, access,
          steal, or modify your information. Learn more about
          <Link
            href={getHrefWithSameQueryParams(
              'https://zcloud.ws/privacy-policy'
            )}
          >
            how we keep your information safe
          </Link>
          .
        </p>

        <p>
          <strong>What are your rights?</strong>
          <br />
          Depending on where you are located geographically, the applicable
          privacy law may mean you have certain rights regarding your personal
          information. Learn more about
          <Link
            href={getHrefWithSameQueryParams(
              'https://zcloud.ws/privacy-policy'
            )}
            className="text-lime-600"
          >
            your privacy rights
          </Link>
          .
        </p>

        <p>
          <strong>How do you exercise your rights?</strong>
          <br />
          The easiest way to exercise your rights is by submitting a
          <Link
            rel="noreferrer"
            href={getHrefWithSameQueryParams(
              'https://zcloud.ws/privacy-policy'
            )}
            target="_blank"
            className="text-lime-600"
          >
            data subject access request
          </Link>
          , or by contacting us. We will consider and act upon any request in
          accordance with applicable data protection laws.
        </p>

        <p>
          <strong>Google Analytics</strong>
          <br />
          We utilize Google Analytics 4 to gather anonymous usage data and
          device information. This information helps us enhance our services and
          tailor content to your needs.
        </p>

        <p>
          Want to learn more about what zcloud.ws does with any information we
          collect? Review the privacy notice in full .
        </p>

        <h5>Table of contents</h5>
        <ol>
          <li>What information do we collect?</li>
          <li>How do we process your information?</li>
          <li>
            What legal bases do we rely on to process your personal information?
          </li>
          <li>When and with whom do we share your personal information?</li>
          <li>Do we use cookies and other tracking technologies?</li>
          <li>How do we handle your social logins?</li>
          <li>How long do we keep your information?</li>
          <li>How do we keep your information safe?</li>
          <li>Do we collect information from minors?</li>
          <li>What are your privacy rights?</li>
          <li>Controls for do-not-track features</li>
          <li>Do california residents have specific privacy rights?</li>
          <li>Do we make updates to this notice?</li>
          <li>How can you contact us about this notice?</li>
          <li>
            How can you review, update, or delete the data we collect from you?
          </li>
          <li>Google Analytics</li>
        </ol>

        <h5>1. What information do we collect?</h5>

        <p>
          <strong>Personal information you disclose to us</strong>
        </p>

        <p>
          <strong>In Short:</strong> We collect personal information that you
          provide to us.
        </p>

        <p>
          We collect personal information that you voluntarily provide to us
          when you register on the Services,express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the Services, or otherwise when you
          contact us.
        </p>

        <p>
          <strong>Personal Information Provided by You.</strong> The personal
          information that we collect depends on the context of your
          interactions with us and the Services, the choices you make, and the
          products and features you use. The personal information we collect may
          include the following:
        </p>
        <ul>
          <li>Email addresses</li>
        </ul>
        <p>
          <strong>Sensitive Information.</strong> We do not process sensitive
          information.
        </p>

        <p>
          <strong>Payment Data.</strong> We may collect data necessary to
          process your payment if you make purchases, such as your payment
          instrument number, and the security code associated with your payment
          instrument. All payment data is stored by Stripe. You may find their
          privacy notice link(s) here:{' '}
          <Link
            rel="noreferrer"
            href="https://stripe.com/en-br/legal/ssa"
            target="_blank"
            className="text-lime-600"
          >
            https://stripe.com/en-br/legal/ssa
          </Link>
          .
        </p>

        <p>
          <strong>Social Media Login Data.</strong> We may provide you with the
          option to register with us using your existing social media account
          details, like your Facebook, Twitter, or other social media account.
          If you choose to register in this way, we will collect the information
          described in the section called&quot; HOW DO WE HANDLE YOUR SOCIAL
          LOGINS? &quot;below.
        </p>

        <p>
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
        </p>

        <p>
          <strong>Information automatically collected</strong>
        </p>

        <p>
          <strong>In Short:</strong> Some information &mdash; such as your
          Internet Protocol (IP) address and/or browser and device
          characteristics &mdash; is collected automatically when you visit our
          Services.
        </p>

        <p>
          We automatically collect certain information when you visit, use, or
          navigate the Services. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Services, and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Services, and for our internal analytics and reporting
          purposes.
        </p>

        <p>
          Like many businesses, we also collect information through cookies and
          similar technologies.
        </p>

        <p>The information we collect includes:</p>
        <ul>
          <li>
            Log and Usage Data. Log and usage data is service-related,
            diagnostic, usage, and performance information our servers
            automatically collect when you access or use our Services and which
            we record in log files. Depending on how you interact with us, this
            log data may include your IP address, device information, browser
            type, and settings and information about your activity in the
            Services (such as the date/time stamps associated with your usage,
            pages and files viewed, searches, and other actions you take such as
            which features you use), device event information (such as system
            activity, error reports (sometimes called&quot;crash dumps&quot;),
            and hardware settings).
          </li>
          <li>
            Device Data. We collect device data such as information about your
            computer, phone, tablet, or other device you use to access the
            Services. Depending on the device used, this device data may include
            information such as your IP address (or proxy server), device and
            application identification numbers, location, browser type, hardware
            model, Internet service provider and/or mobile carrier, operating
            system, and system configuration information.
          </li>
          <li>
            Location Data. We collect location data such as information about
            your device&apos;s location, which can be either precise or
            imprecise. How much information we collect depends on the type and
            settings of the device you use to access the Services. For example,
            we may use GPS and other technologies to collect geolocation data
            that tells us your current location (based on your IP address). You
            can opt out of allowing us to collect this information either by
            refusing access to the information or by disabling your Location
            setting on your device. However, if you choose to opt out, you may
            not be able to use certain aspects of the Services.
          </li>
        </ul>

        <h5>2. How do we process your information?</h5>

        <p>
          <strong>In Short:</strong> We process your information to provide,
          improve, and administer our Services, communicate with you, for
          security and fraud prevention, and to comply with law. We may also
          process your information for other purposes with your consent.
        </p>

        <p>
          <strong>
            We process your personal information for a variety of reasons,
            depending on how you interact with our Services, including:
          </strong>
        </p>
        <ul>
          <li>
            <strong>
              To facilitate account creation and authentication and otherwise
              manage user accounts.
            </strong>{' '}
            We may process your information so you can create and log in to your
            account, as well as keep your account in working order.
          </li>
          <li>
            <strong>
              To save or protect an individual&apos;s vital interest.
            </strong>{' '}
            We may process your information when necessary to save or protect an
            individual&rsquo;s vital interest, such as to prevent harm.
          </li>
        </ul>

        <h5>3. What legal bases do we rely on to process your information?</h5>

        <p>
          <strong>In Short:</strong> We only process your personal information
          when we believe it is necessary and we have a valid legal reason
          (i.e.,legal basis) to do so under applicable law, like with your
          consent, to comply with laws, to provide you with services to enter
          into or fulfill our contractual obligations, to protect your rights,
          or to fulfill our legitimate business interests.
        </p>

        <p>
          <strong>
            <u>
              If you are located in the EU or UK, this section applies to you.
            </u>
          </strong>
        </p>

        <p>
          The General Data Protection Regulation (GDPR) and UK GDPR require us
          to explain the valid legal bases we rely on in order to process your
          personal information. As such, we may rely on the following legal
          bases to process your personal information:
        </p>
        <ul>
          <li>
            <strong>Consent.</strong> We may process your information if you
            have given us permission (i.e., consent) to use your personal
            information for a specific purpose. You can withdraw your consent at
            any time.
          </li>
          <li>
            <strong>Legal Obligations.</strong> We may process your information
            where we believe it is necessary for compliance with our legal
            obligations, such as to cooperate with a law enforcement body or
            regulatory agency, exercise or defend our legal rights, or disclose
            your information as evidence in litigation in which we are involved.
            <br />
          </li>
          <li>
            <strong>Vital Interests.</strong> We may process your information
            where we believe it is necessary to protect your vital interests or
            the vital interests of a third party, such as situations involving
            potential threats to the safety of any person.
          </li>
        </ul>

        <p>
          <strong>
            <u>If you are located in Canada, this section applies to you.</u>
          </strong>
        </p>

        <p>
          We may process your information if you have given us specific
          permission (i.e.,express consent) to use your personal information for
          a specific purpose, or in situations where your permission can be
          inferred (i.e.,implied consent). You can withdraw your consent at any
          time.
        </p>

        <p>
          In some exceptional cases, we may be legally permitted under
          applicable law to process your information without your consent,
          including, for example:
        </p>
        <ul>
          <li>
            If collection is clearly in the interests of an individual and
            consent cannot be obtained in a timely way
          </li>
          <li>For investigations and fraud detection and prevention</li>
          <li>For business transactions provided certain conditions are met</li>
          <li>
            If it is contained in a witness statement and the collection is
            necessary to assess, process, or settle an insurance claim
          </li>
          <li>
            For identifying injured, ill, or deceased persons and communicating
            with next of kin
          </li>
          <li>
            If we have reasonable grounds to believe an individual has been, is,
            or may be victim of financial abuse
          </li>
          <li>
            If it is reasonable to expect collection and use with consent would
            compromise the availability or the accuracy of the information and
            the collection is reasonable for purposes related to investigating a
            breach of an agreement or a contravention of the laws of Canada or a
            province
          </li>
          <li>
            If disclosure is required to comply with a subpoena, warrant, court
            order, or rules of the court relating to the production of records
          </li>
          <li>
            If it was produced by an individual in the course of their
            employment, business, or profession and the collection is consistent
            with the purposes for which the information was produced
          </li>
          <li>
            If the collection is solely for journalistic, artistic, or literary
            purposes
          </li>
          <li>
            If the information is publicly available and is specified by the
            regulations
          </li>
        </ul>

        <h5>4. When and with whom do we share your personal information?</h5>

        <p>
          <strong>In Short:</strong> We may share information in specific
          situations described in this section and/or with the following
          categories of third parties. We also use Google Analytics for
          analytics purposes.
        </p>

        <p>
          <strong>
            Vendors, Consultants, and Other Third-Party Service Providers.
          </strong>{' '}
          We may share your data with third-party vendors, service providers,
          contractors, or agents (&quot;
          <strong>third parties</strong>&quot;) who perform services for us or
          on our behalf and require access to such information to do that work.
          We have contracts in place with our third parties, which are designed
          to help safeguard your personal information. This means that they
          cannot do anything with your personal information unless we have
          instructed them to do it. They will also not share your personal
          information with any organization apart from us. They also commit to
          protect the data they hold on our behalf and to retain it for the
          period we instruct. The categories of third parties we may share
          personal information with are as follows:
        </p>
        <ul>
          <li>Performance Monitoring Tools</li>
          <li>Data Analytics Services</li>
          <li>Ad Networks</li>
          <li>Cloud Computing Services</li>
        </ul>

        <p>
          We also may need to share your personal information in the following
          situations:
        </p>
        <ul>
          <li>
            <strong>Business Transfers.</strong> We may share or transfer your
            information in connection with, or during negotiations of, any
            merger, sale of company assets, financing, or acquisition of all or
            a portion of our business to another company.
          </li>
          <li>
            <strong>Affiliates.</strong> We may share your information with our
            affiliates, in which case we will require those affiliates to honor
            this privacy notice. Affiliates include our parent company and any
            subsidiaries, joint venture partners, or other companies that we
            control or that are under common control with us.
          </li>
          <li>
            <strong>Business Partners.</strong> We may share your information
            with our business partners to offer you certain products, services,
            or promotions.
          </li>
        </ul>

        <h5>5. Do we use cookies and other tracking technologies?</h5>

        <p>
          <strong>In Short:</strong> We may use cookies and other tracking
          technologies to collect and store your information.
        </p>

        <p>
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to access or store information. Specific information about
          how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Notice.
        </p>

        <h5>6. How do we handle your social logins?</h5>

        <p>
          <strong>In Short:</strong> If you choose to register or log in to our
          Services using a social media account, we may have access to certain
          information about you.
        </p>

        <p>
          Our Services offer you the ability to register and log in using your
          third-party social media account details (like your Facebook or
          Twitter logins). Where you choose to do this, we will receive certain
          profile information about you from your social media provider. The
          profile information we receive may vary depending on the social media
          provider concerned, but will often include your name, email address,
          friends list, and profile picture, as well as other information you
          choose to make public on such a social media platform.
        </p>

        <p>
          We will use the information we receive only for the purposes that are
          described in this privacy notice or that are otherwise made clear to
          you on the relevant Services. Please note that we do not control, and
          are not responsible for, other uses of your personal information by
          your third-party social media provider. We recommend that you review
          their privacy notice to understand how they collect, use, and share
          your personal information, and how you can set your privacy
          preferences on their sites and apps.
        </p>

        <h5>7. How long do we keep your information?</h5>

        <p>
          <strong>In Short:</strong> We keep your information for as long as
          necessary to fulfill the purposes outlined in this privacy notice
          unless otherwise required by law.
        </p>

        <p>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements).No purpose in this notice
          will require us keeping your personal information for longer thanone
          hundred twenty (120)months past the termination of the user&apos;s
          account.
        </p>

        <p>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>

        <h5>8. How do we keep your information safe?</h5>

        <p>
          <strong>In Short:</strong> We aim to protect your personal information
          through a system of organizational and technical security measures.
        </p>

        <p>
          We have implemented appropriate and reasonable technical and
          organizational security measures designed to protect the security of
          any personal information we process. However, despite our safeguards
          and efforts to secure your information, no electronic transmission
          over the Internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorized third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your
          personal information, transmission of personal information to and from
          our Services is at your own risk. You should only access the Services
          within a secure environment.
        </p>

        <h5>9. Do we collect information from minors?</h5>

        <p>
          <strong>In Short:</strong> We do not knowingly collect data from or
          market to children under 18 years of age.
        </p>

        <p>
          We do not knowingly solicit data from or market to children under 18
          years of age. By using the Services, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent&rsquo;s use of the Services. If we
          learn that personal information from users less than 18 years of age
          has been collected, we will deactivate the account and take reasonable
          measures to promptly delete such data from our records. If you become
          aware of any data we may have collected from children under age 18,
          please contact us atsupport@zcloud.ws.
        </p>

        <h5>10. What are your privacy rights?</h5>

        <p>
          <strong>In Short:</strong> In some regions, such as the European
          Economic Area (EEA), United Kingdom (UK), and Canada, you have rights
          that allow you greater access to and control over your personal
          information. You may review, change, or terminate your account at any
          time.
        </p>

        <p>
          In some regions (like the EEA, UK, and Canada), you have certain
          rights under applicable data protection laws. These may include the
          right (i) to request access and obtain a copy of your personal
          information, (ii) to request rectification or erasure; (iii) to
          restrict the processing of your personal information; and (iv) if
          applicable, to data portability. In certain circumstances, you may
          also have the right to object to the processing of your personal
          information. You can make such a request by contacting us by using the
          contact details provided in the section&quot; HOW CAN YOU CONTACT US
          ABOUT THIS NOTICE? &quot;below.
        </p>

        <p>
          We will consider and act upon any request in accordance with
          applicable data protection laws.
        </p>

        <p>
          If you are located in the EEA or UK and you believe we are unlawfully
          processing your personal information, you also have the right to
          complain to your Member State data protection authority or UK data
          protection authority .
        </p>

        <p>
          If you are located in Switzerland, you may contact the Federal Data
          Protection and Information Commissioner .
        </p>

        <p>
          <strong className="text-light-500">Withdrawing your consent:</strong>{' '}
          If we are relying on your consent to process your personal
          information, which may be express and/or implied consent depending on
          the applicable law, you have the right to withdraw your consent at any
          time. You can withdraw your consent at any time by contacting us by
          using the contact details provided in the section &quot; HOW CAN YOU
          CONTACT US ABOUT THIS NOTICE? &quot;below or updating your
          preferences.
        </p>

        <p>
          However, please note that this will not affect the lawfulness of the
          processing before its withdrawal nor,when applicable law allows,will
          it affect the processing of your personal information conducted in
          reliance on lawful processing grounds other than consent.
        </p>

        <p>
          <strong className="text-light-500">
            Opting out of marketing and promotional communications:
          </strong>{' '}
          You can unsubscribe from our marketing and promotional communications
          at any time by clicking on the unsubscribe link in the emails that we
          send,or by contacting us using the details provided in the
          section&quot; HOW CAN YOU CONTACT US ABOUT THIS NOTICE? &quot;below.
          You will then be removed from the marketing lists. However, we may
          still communicate with you &mdash; for example, to send you
          service-related messages that are necessary for the administration and
          use of your account, to respond to service requests, or for other
          non-marketing purposes.
        </p>

        <p>
          <strong className="text-light-500">Account Information: </strong>
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
        </p>
        <ul>
          <li>Log in to your account settings and update your user account.</li>
        </ul>
        <p>
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          legal terms and/or comply with applicable legal requirements.
        </p>

        <p>
          <strong className="text-light-500">
            Cookies and similar technologies:
          </strong>{' '}
          Most Web browsers are set to accept cookies by default. If you prefer,
          you can usually choose to set your browser to remove cookies and to
          reject cookies. If you choose to remove cookies or reject cookies,
          this could affect certain features or services of our Services. You
          may also opt out of interest-based advertising by advertisers on our
          Services.
        </p>

        <p>
          If you have questions or comments about your privacy rights, you may
          email us atsupport@zcloud.ws.
        </p>

        <h5>11. Controls for do-not-track features</h5>

        <p>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track (&quot;DNT&quot;) feature or
          setting you can activate to signal your privacy preference not to have
          data about your online browsing activities monitored and collected. At
          this stage no uniform technology standard for recognizing and
          implementing DNT signals has been finalized. As such, we do not
          currently respond to DNT browser signals or any other mechanism that
          automatically communicates your choice not to be tracked online. If a
          standard for online tracking is adopted that we must follow in the
          future, we will inform you about that practice in a revised version of
          this privacy notice.
        </p>

        <h5>12. Do california residents have specific privacy rights?</h5>

        <p>
          <strong>In Short:</strong> Yes, if you are a resident of California,
          you are granted specific rights regarding access to your personal
          information.
        </p>

        <p>
          California Civil Code Section 1798.83, also known as the&quot;Shine
          The Light&quot;law, permits our users who are California residents to
          request and obtain from us, once a year and free of charge,
          information about categories of personal information (if any) we
          disclosed to third parties for direct marketing purposes and the names
          and addresses of all third parties with which we shared personal
          information in the immediately preceding calendar year. If you are a
          California resident and would like to make such a request, please
          submit your request in writing to us using the contact information
          provided below.
        </p>

        <p>
          If you are under 18 years of age, reside in California, and have a
          registered account with Services, you have the right to request
          removal of unwanted data that you publicly post on the Services. To
          request removal of such data, please contact us using the contact
          information provided below and include the email address associated
          with your account and a statement that you reside in California. We
          will make sure the data is not publicly displayed on the Services, but
          please be aware that the data may not be completely or comprehensively
          removed from all our systems (e.g.,backups, etc.).
        </p>

        <h5>13. Do we make updates to this notice?</h5>

        <p>
          <strong>In Short:</strong> Yes, we will update this notice as
          necessary to stay compliant with relevant laws.
        </p>

        <p>
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated &quot;Revised&quot; date and
          the updated version will be effective as soon as it is accessible. If
          we make material changes to this privacy notice, we may notify you
          either by prominently posting a notice of such changes or by directly
          sending you a notification. We encourage you to review this privacy
          notice frequently to be informed of how we are protecting your
          information.
        </p>

        <h5>14. How can you contact us about this notice?</h5>

        <p>
          If you have questions or comments about this notice, you may email us
          atsupport@zcloud.wsor by post to:
        </p>

        <p>
          zcloud.ws
          <br />
          Av Paulista, 1471, Conj. 1.110, Sala 01, Bela Vista
          <br />
          S&atilde;o Paulo, SP01311-927
          <br />
          Brazil
        </p>

        <h5>
          15. How can you review, update, or delete the data we collect from
          you?
        </h5>

        <p>
          Based on the applicable laws of your country, you may have the right
          to request access to the personal information we collect from you,
          change that information, or delete it. To request to review, update,
          or delete your personal information, please fill out and submit a data
          subject access request .
        </p>

        <h5>16. Google Analytics</h5>

        <p>
          We use Google Analytics, a web analytics service provided by Google
          Inc. ("Google"). Google Analytics uses "cookies," which are text files
          placed on your computer, to help analyze how users interact with our
          site. The information generated by the cookie about your use of our
          website will be transmitted to and stored by Google on servers in the
          United States.
        </p>
        <p>
          Google will use this information for the purpose of evaluating your
          use of the website, compiling reports on website activity for website
          operators, and providing other services relating to website activity
          and internet usage. Google may also transfer this information to third
          parties where required to do so by law, or where such third parties
          process the information on Google's behalf. Google will not associate
          your IP address with any other data held by Google.
        </p>
        <p>
          You can opt-out of Google Analytics by installing the browser plugin
          available at{' '}
          <Link
            rel="noreferrer"
            href="https://chromewebstore.google.com/detail/google-analytics-opt-out/fllaojicojecljbmefodhfapmkghcbnh?hl=en"
            target="_blank"
            className="text-lime-600"
          >
            Google Analytics Opt-out Browser Add-on
          </Link>
          .
        </p>
      </div>
    </section>
  </>
);
