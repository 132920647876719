import { Link } from '../../components/Link';
import { getHrefWithSameQueryParams } from '../../utilities';
import { RoutePaths } from '../../RoutePaths';

export const CSPoliPage = () => (
  <>
    <section className="mt-8 px-4 lg:mt-16 lg:px-8">
      <div className="container mx-auto">
        <div className="mx-auto flex max-w-medium flex-col gap-8 lg:flex-row">
          <div className="shrink-0 grow-0 pt-2 lg:basis-[150px]">
            <Link
              event="website_csPoli_back_click"
              to={getHrefWithSameQueryParams(RoutePaths.CASE_STUDIES)}
              className="text-p text-lime-600"
            >
              <i className="fa fa-arrow-left"></i> <span>All case studies</span>
            </Link>
          </div>
          <div className="flex flex-col gap-6">
            <h4 className="text-h4 text-light-500">
              Efficient Transformation: Simplifying Infrastructure and Reducing
              Costs for Poli
            </h4>
            <h6 className="text-h6 font-light text-light-500">
              "Migrated from AWS and others, now many environments have all
              services are under the same roof with high-level support! zCloud
              works brilliantly!"
            </h6>
            <div className="flex items-center gap-2">
              <img
                src="https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/cs-poli-photo.png"
                alt="Gabriel Henrique"
              />
              <p className="flex flex-col">
                <small className="text-small text-light-700">
                  Gabriel Henrique
                </small>
                <small className="text-small text-light-800">Co-founder</small>
              </p>
            </div>
            <p className="text-p">
              Poli encountered challenges managing their broad range of business
              solutions, such as CRM and e-commerce, across multiple
              environments, including numerous non-production setups. In their
              quest for a more simplified and developer-centric infrastructure
              for their non-production environments, they sought help from
              zCloud.
            </p>
            <p className="text-p">
              Currently, we operate over 15 databases and 110 app environments
              on zCloud. These include a diverse range of databases such as
              MySQL, PostgreSQL, MongoDB, Redis, and RabbitMQ, along with
              Meilisearch. Our application portfolio comprises various languages
              and frameworks, including PHP, JavaScript, Node.js, and Go.
            </p>
            <p className="text-p">
              Transitioning their systems seamlessly to zCloud's platform
              equipped Poli with newfound control and improved operational
              efficiency. The consolidation of their operations, combined with
              the advantage of expert support from zCloud, resulted in
              significant cost reduction in infrastructure management for Poli.
            </p>
          </div>
          <aside className="shrink-0 grow-0 lg:basis-[250px]">
            <div className="flex flex-col items-start gap-2 rounded-8 bg-neutral-500 p-4">
              <img
                src="https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/cs-poli-logo.png"
                alt="Poli"
                className="mb-2"
              />
              <p className="text-p font-medium text-light-500">Poli</p>
              <p className="text-p text-light-700">
                Integrated business solutions for SMEs, covering CRM, e-commerce
                and multichannel service.
              </p>
              <p className="text-p text-light-700">
                <span className="text-light-500">1.100+</span> clients
                <br />
                <span className="text-light-500">1M+</span> messages per day
              </p>
              <Link
                event="website_csPoli_website_click"
                className="text-p text-lime-600"
                href="https://poli.digital/"
                target="_blank"
              >
                poli.digital
              </Link>
            </div>
          </aside>
        </div>
      </div>
    </section>
  </>
);
