import { getHrefWithSameQueryParams } from '../utilities';
import { Link } from '../components/Link';
import { RoutePaths } from '../RoutePaths';
import React from 'react';
import { Button } from '../components/Button';
import SmallFooter from './sections/SmallFooter';

export const LoadTestingPage = () => {
  const steps = [
    {
      title: `Load testing runs`,
      description: `Our infrastructure sends a significant surge of requests to your app's URL, exceeding what your own machines can achieve.`,
    },
    {
      title: `Collect metrics`,
      description: `We organize data, adjusting test scales for precise performance limit identification.`,
    },
    {
      title: `Provide insights`,
      description: `Receive tailored insights that uncover bottlenecks and potential enhancements.`,
    },
    {
      title: `Report`,
      description: (
        <>
          Access a detailed PDF report, featuring graphs, tables, and test
          summaries. <br />
          <Link
            event="website_load_testing_downloadReport_click"
            className="text-lime-600 mt-3 inline-block"
            href={getHrefWithSameQueryParams(
              'https://zcloud-static-assets.s3.amazonaws.com/load-testing/zcloud-load-testing-report.pdf'
            )}
            target="_blank"
          >
            Download sample report
          </Link>
        </>
      ),
    },
    {
      title: `Free 15-minute consultation (optional)`,
      description: `Request a free 15-minute consultation call after you get your report if you want to discuss it.`,
    },
  ];

  return (
    <>
      <section
        data-id="workshop"
        className="mt-16 px-4 lg:px-8 bg-hero-cloud bg-no-repeat bg-[center_-120px]"
      >
        <div className="mx-auto container">
          <div className="max-w-medium mx-auto flex flex-col items-center gap-8">
            <div className="block max-w-[140px] md:max-w-[190px]">
              <img
                className="block"
                src="https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/zcloud-logo.svg"
                alt="zCloud Logo"
              />
            </div>

            <h2 className="text-h3 md:text-h2 text-light-100 text-center max-w-[875px] pt-12">
              <span className="text-neon">
                Discover your app's&nbsp;limits.{' '}
              </span>
              <br />
              No downtime, prepared for growth.
            </h2>
            <h5 className="text-h5 text-light-100 text-center max-w-[875px]">
              Our load testing helps you understand how your app handles stress,
              if it can manage more users and traffic well, and ways to optimize
              your resources.
            </h5>

            <div className="border border-neutral-200 px-8 py-6 flex flex-col items-center rounded-8">
              <p className="text-p text-light-500 text-center max-w-[450px] mb-6">
                Get started now and be among the first 50 to enjoy these
                services for free, valued at $200.
              </p>

              <div className="bg-neon p-px rounded-8 mb-4">
                <div className="flex flex-col sm:flex-row gap-6 items-center bg-neutral-500 p-6 rounded-8">
                  <Button
                    className="btn-primary grow-0 shrink-0"
                    event="website_load_testing_getStarted_click"
                    onClick={() =>
                      window.open(
                        getHrefWithSameQueryParams(
                          'https://forms.gle/1KXGuPRzXxnsBxoZ7'
                        ),
                        '_blank'
                      )
                    }
                  >
                    Get started now
                  </Button>
                  <div className="flex flex-col items-center">
                    <div className="flex flex-nowrap gap-2">
                      <h6 className="text-h6 text-light-500">Price:</h6>
                      <h6 className="text-h6 text-light-700">
                        <s>$200</s>
                      </h6>
                      <h6 className="text-h6 font-regular text-neon">FREE</h6>
                    </div>
                    <p className="text-p text-light-500">
                      Only 9 free spots left
                    </p>
                  </div>
                </div>
              </div>

              <p className="text-p text-light-700">
                You wll get your report in less than 24 hours.
              </p>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 pt-8">
              <h4 className="text-h4 text-light-500 text-center lg:text-right">
                How does it work?
              </h4>
              <ul className="pt-2">
                {steps.map(({ title, description }, index) => (
                  <li className="flex gap-4 mb-8">
                    <span className="border-2 border-light-700 rounded-full w-[29px] h-[29px] shrink-0 grow-0 text-center text-light-500">
                      {index + 1}
                    </span>
                    <div>
                      <h6 className="text-h6 font-regular text-light-500 mb-2">
                        {title}
                      </h6>
                      <p className="text-p">{description}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className="max-w-small text-center pt-36">
              <p className="text-p text-light-700 mb-6">
                zCloud is your cloud hosting solution that removes complexity
                and simplifies software shipping. Engineered for versatility.
                Designed for all programming languages. Tailored for apps and
                databases. Comes with amazing support and competitive pricing.
              </p>
              <Link
                event="website_load_testing_learnMore_click"
                href={getHrefWithSameQueryParams(RoutePaths.HOME)}
                className="btn-outline"
              >
                Find out more <i className="fa fa-arrow-right" />
              </Link>
            </div>

            <SmallFooter />
          </div>
        </div>
      </section>
    </>
  );
};
