import { Link } from '../../components/Link';
import { getHrefWithSameQueryParams } from '../../utilities';
import { RoutePaths } from '../../RoutePaths';
export const CSEatFreshPage = () => (
  <>
    <section className="mt-8 lg:mt-16 px-4 lg:px-8">
      <div className="mx-auto container">
        <div className="mx-auto max-w-medium flex flex-col lg:flex-row gap-8">
          <div className="grow-0 shrink-0 lg:basis-[150px] pt-2">
            <Link
              event="website_csEatFresh_back_click"
              to={getHrefWithSameQueryParams(RoutePaths.CASE_STUDIES)}
              className="text-p text-lime-600"
            >
              <i className="fa fa-arrow-left"></i> <span>All case studies</span>
            </Link>
          </div>
          <div className="flex flex-col gap-6">
            <h4 className="text-h4 text-light-500">
              Unleashing Cost-Efficiency and Enhanced Hosting for Eat Fresh Tech
            </h4>
            <h6 className="text-h6 font-light text-light-500">
              "zCloud is a dream PaaS with an amazing dashboard, eliminating
              hours of manual work. They helped me migrate seamlessly. Highly
              recommend!"
            </h6>
            <div className="flex gap-2 items-center">
              <img
                src="https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/cs-eatfresh-photo.png"
                alt="Matt Durr"
              />
              <p className="flex flex-col">
                <small className="text-small text-light-700">Matt Durr</small>
                <small className="text-small text-light-800">Founder</small>
              </p>
            </div>
            <p className="text-p">
              Eat Fresh Tech confronted a challenge managing their 15+ Meteor
              Apps on a large Digital Ocean server. Seeking a more
              cost-effective and streamlined hosting solution, they turned to
              zCloud, leading to a remarkable transformation.
            </p>
            <p className="text-p">
              Migrating 15+ Meteor apps and a MongoDB cluster to zCloud proved
              to be a game-changer, as hosting costs were cut in half, without
              compromising on performance. The zCloud team delivered exceptional
              support throughout the seamless migration, ensuring a worry-free
              transition. With zCloud's innovative management tools and
              dashboard, Eat Fresh Tech's site management has become incredibly
              efficient, enabling them to devote more time and resources to
              develop fresh and innovative tech solutions for their users. The
              partnership with zCloud has opened new possibilities, empowering
              Eat Fresh Tech to focus on growth and delivering a superior user
              experience.
            </p>
          </div>
          <aside className="grow-0 shrink-0 lg:basis-[250px]">
            <div className="bg-neutral-500 p-4 rounded-8 flex flex-col gap-2 items-start">
              <img
                src="https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/cs-eatfresh-logo.png"
                alt="QuickCoach"
                className="mb-2"
              />
              <p className="text-p font-medium text-light-500">
                Eat Fresh Tech
              </p>
              <p className="text-p text-light-700">
                The dedicated ecommerce platform for growing meal prep companies.
              </p>
              <p className="text-p text-light-700">
                <span className="text-light-500">25,000+</span> users
                <br />
                <span className="text-light-500">170,000+</span> orders
                <br />
                <span className="text-light-500">850,000+</span> meals
              </p>
              <Link
                event="website_csEatFresh_website_click"
                className="text-p text-lime-600"
                href="https://www.eatfresh.tech/"
                target="_blank"
              >
                eatfresh.tech
              </Link>
            </div>
          </aside>
        </div>
      </div>
    </section>
  </>
);
