const apiKey = '1a62fdec-6b62-430c-85c3-fd817022b289';
const url = 'https://app.lemeno.io/api';

async function sendToLemeno({ path, email, tags }) {
  if (!url || !apiKey) {
    console.log(`MOCK::sendToLemeno ${path} ${email}`);
    return Promise.resolve('success mock');
  }
  const response = await fetch(`${url}${path}`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      apiKey,
      originalSlug: 'zcloud',
      email,
      tags,
      userCreationFields: {
        language: 'en-US',
        utmSignUp: {
          utm_source: 'zcloud',
          utm_medium: 'api',
        },
      },
    }),
  });
  return response.json();
}

export const subscribeToLemeno = async (arg) => {
  const data = await sendToLemeno({ ...arg, path: '/subscribe' });
  console.log('subscribeToLemeno data', data);
  return data;
};

export const unsubscribeToLemeno = async (arg) => {
  const data = await sendToLemeno({ ...arg, path: '/unsubscribe' });
  console.log('unsubscribeToLemeno data', data);
  return data;
};
