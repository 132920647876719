// curl "http://localhost:3000/api/sendFeedbackEmail" -X POST -H "Accept: application/json" -H "Content-Type: application/json" -d '{"fromEmail": "client@example.com", "fromName": "client", "content": "Olá, preciso de ajuda! Teste Filipe", "to": "contato@quave.com.br",     "from": "contato@quave.com.br", "subject": "Contact Quave Site"}'
import { useState } from 'react';
import { Button } from './Button';
import { sendEmail } from './sendEmail';

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
export const ContactUs = ({ showContact, setShowContact, toggleContactUs }) => {
  const [fromEmail, setFromEmail] = useState('');
  const [fromName, setFromName] = useState('');
  const [content, setContent] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const onClose = () => {
    reset();
    setSuccess('');
    toggleContactUs();
  };

  function reset() {
    setFromEmail('');
    setFromName('');
    setContent('');
    setError('');
  }

  const onSubmit = (e) => {
    e.preventDefault();

    setError('');
    if (!isValidEmail()()) {
      setError('Invalid email address. Please check your email for typos.');
      return;
    }
    
    sendEmail({
      fromEmail,
      fromName,
      content,
      setSuccess,
      setError,
      reset
    });
  };

  const isValidEmail = (fromEmailChanged) => () => {
    const email = fromEmailChanged || fromEmail;

    if (!email) {
      return false;
    }

    return validateEmail(email);
  };

  return (
    <div
      className={`fixed bottom-0 left-0 right-0 top-0 z-10 overflow-auto bg-black_A92 p-4 shadow-lg sm:items-center sm:justify-center lg:p-8 ${
        showContact ? 'sm:flex' : 'hidden'
      }`}
    >
      <section className="sh-large relative flex min-h-[450px] max-w-[600px] flex-col items-stretch rounded-8 bg-neutral-500 md:flex-row md:overflow-hidden">
        <button
          className="absolute right-[20px] top-[20px] p-2"
          onClick={onClose}
        >
          <i className="fa fas fa-times text-xl text-light-500"></i>
        </button>
        <form onSubmit={onSubmit} className="space-y-4 p-4 sm:p-8">
          <h4 className="mb-2 text-h4 text-light-500">Contact us</h4>
          <div className="rounded-4 border border-warning-700 bg-warning-700A10 px-4 py-2 text-p text-warning-200">
            Important notice: support request replies may go to your SPAM
            folder.
            <br />
            <br />
            As a temporary measure, we're asking everyone to email us directly
            at{' '}
            <a href="mailto:support@zcloud.ws" className="text-lime-600">
              support@zcloud.ws
            </a>{' '}
            If you contact us using this form please check your SPAM folder for
            replies.
          </div>
          <p className="text-p text-light-700">
            Got questions? We're here to help! Reach out to us anytime and we'll
            respond within an hour during working hours. Let's get started!
          </p>

          {!success && (
            <>
              <input
                name="name"
                type="text"
                placeholder="Name"
                className="w-full rounded-4 bg-neutral-400 px-4 py-2 text-p text-light-500 placeholder:text-light-700"
                value={fromName}
                onChange={(e) => setFromName(e.target.value)}
              />

              <input
                name="email"
                className="w-full rounded-4 bg-neutral-400 px-4 py-2 text-p text-light-500 placeholder:text-light-700"
                placeholder="Email"
                type="email"
                value={fromEmail}
                onChange={(e) => setFromEmail(e.target.value)}
              />

              <textarea
                name="content"
                className="w-full rounded-4 bg-neutral-400 px-4 py-2 text-p text-light-500 placeholder:text-light-700"
                placeholder="Message"
                value={content}
                rows="4"
                onChange={(e) => setContent(e.target.value)}
              />
            </>
          )}

          {error && <p className="text-small text-red-500">{error}</p>}
          {success && <p className="text-small text-lime-700">{success}</p>}

          {!success && (
            <Button event="website_contactUs_sendMessage_click">
              Send message
            </Button>
          )}

          <p className="text-p">
            Or email us directly at{' '}
            <span className="text-lime-700">support@zcloud.ws</span>
          </p>
        </form>
      </section>
    </div>
  );
};
