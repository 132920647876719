import React from 'react';
import { Link as RawLink } from 'react-router-dom';
import { simpleanalyticsSendEvent } from '../simpleAnalyticsSendEvent';

export const Link = ({ children, event, onClick, ...rest }) => {
  const { href, to } = rest;
  const isExternal = (href || to)?.startsWith('http') || (href || to)?.startsWith('mailto');
  const LinkComponent = isExternal ? 'a' : RawLink;

  const onClickFinal = (...args) => {
    let events = [event]
    if((to || href)?.includes('app.zcloud.ws')) {
      events.push('website_goToApp_click')
    }
    if((to || href)?.includes('app.zcloud.ws/access')) {
      events.push('website_goToAppAccess_click')
    }
    if((to || href)?.includes('app.zcloud.ws/access?logIn=true')) {
      events.push('website_goToAppLogIn_click')
    }
    simpleanalyticsSendEvent(events.filter(Boolean).join(','));
    if (onClick) {
      onClick(...args);
    }
  };

  const linkOptions = {
    ...rest,
    ...(isExternal ? { href: href || to } : { to: to || href }),
  };
  return (
    <LinkComponent onClick={onClickFinal} {...linkOptions}>
      {children}
    </LinkComponent>
  );
};
