/**
 * How to use as a direct onClick={() => simpleanalyticsSendEvent('my-event-name')}
 * @param event
 */
export const simpleanalyticsSendEvent = (event) => {
  try {
    if (!event) {
      return;
    }
    // eslint-disable-next-line no-undef,camelcase
    const saEvent = sa_event;

    if (saEvent) {
      console.log('Sending event to SimpleAnalytics', event);
      if (event.includes(',')) {
        event.split(',').forEach(saEvent);
        return;
      }
      saEvent(event);
    }
  } catch (e) {
    // we removed it for now
  }
};
