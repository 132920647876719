import { Link } from '../../components/Link';
import { getHrefWithSameQueryParams } from '../../utilities';
import { RoutePaths } from '../../RoutePaths';

export const CSCaughtPage = () => (
  <>
    <section className="mt-8 px-4 lg:mt-16 lg:px-8">
      <div className="container mx-auto">
        <div className="max-w-medium mx-auto flex flex-col gap-8 lg:flex-row">
          <div className="shrink-0 grow-0 pt-2 lg:basis-[150px]">
            <Link
              event="website_csCaught_back_click"
              to={getHrefWithSameQueryParams(RoutePaths.CASE_STUDIES)}
              className="text-p text-lime-600"
            >
              <i className="fa fa-arrow-left"></i> <span>All case studies</span>
            </Link>
          </div>
          <div className="flex flex-col gap-6">
            <h4 className="text-h4 text-light-500">
              Streamlined Infrastructure and Cost Reduction: Caught's experience with zCloud
            </h4>
            <h6 className="text-h6 text-light-500 font-light">
              "We save 75 to 80% of our server running costs, and on top of that, receive better service whenever we
              have a question."
            </h6>
            <div className="flex items-center gap-2">
              <div className="w-12">
                <img
                    src="https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/cs-caught-photo.jpeg"
                    alt="Mark Naus"
                    className="rounded-full"
                />
              </div>
              <p className="flex flex-col">
                <small className="text-small text-light-700">Mark Naus</small>
                <small className="text-small text-light-800">Founder</small>
              </p>
            </div>
            <p className="text-p">
              Caught, a platform for creating location-based apps, faced challenges managing their complex infrastructure
              across multiple environments. With hundreds of thousands of daily users, they needed a reliable,
              efficient, and cost-effective solution for their non-production and production environments.
            </p>
            <p className="text-p">
              The company's tech stack includes multiple Meteor applications, MongoDB for the database, and Redis for
              real-time functionality. They were initially cautious about migrating due to the live nature of their
              business but were impressed by zCloud's approach and support.
            </p>
            <p className="text-p">
              After a careful migration process, Caught successfully transitioned all their servers and databases to zCloud
              by the end of 2023. The move resulted in significant performance improvements and cost savings, with the
              overall cost of running their applications decreasing by 75-80%.
            </p>
            <p className="text-p">
              Caught particularly appreciated zCloud's professional support, well-thought-out setup, and features like easy
              configuration management and flexible DNS handling. The migration not only improved their infrastructure
              but also provided them with better service and support for their ongoing operations.
            </p>
          </div>
          <aside className="shrink-0 grow-0 lg:basis-[250px]">
            <div className="rounded-8 flex flex-col items-start gap-2 bg-neutral-500 p-4">
              <div className="w-24">
                <img
                    src="https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/cs-caught-logo.png"
                    alt="Caught"
                    className="mb-2"
                />
              </div>
              <p className="text-p text-light-500 font-medium">Caught</p>
              <p className="text-p text-light-700">
                Whatever you think of, Caught helps you to create an
                extraordinary experience that encourages people to start
                exploring!
              </p>
              <p className="text-p text-light-700">
                <span className="text-light-500">10K+</span> tours
                <br/>
                <span className="text-light-500">500K+</span> unique users p/year
              </p>
              <Link
                  event="website_csCaught_website_click"
                  className="text-p text-lime-600"
                  href="https://caught.nu/"
                  target="_blank"
              >
                caught.nu
              </Link>
            </div>
          </aside>
        </div>
      </div>
    </section>
  </>
);
