import CaseStudiesSection from '../pages/sections/CaseStudies';

export const CaseStudiesPage = () => (
  <>
    <section data-id="testimonials" className="mt-16 px-4 lg:px-8">
      <div className="mx-auto container flex flex-col items-center">
        <h2 className="text-h3 md:text-h2 text-light-100 text-center pb-16">
          Case studies
        </h2>

        <CaseStudiesSection page="caseStudies" />
      </div>
    </section>
  </>
);
