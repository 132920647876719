import React from 'react';

export const ListComponent = ({ items, icon }) => {
  return (
    <ul className="flex max-w-small flex-col gap-4">
      {items.map((item, index) => (
        <li key={index} className="flex items-start gap-4 text-p">
          <i className={`fa ${icon || 'fa-check'} text-h6 text-lime-500`} />
          <p>{item}</p>
        </li>
      ))}
    </ul>
  );
};
