import { simpleanalyticsSendEvent } from '../simpleAnalyticsSendEvent';

export const Button = ({ onClick, event, className, children, disabled }) => {
  const onClickFinal = (...args) => {
    simpleanalyticsSendEvent(event);
    if (onClick) {
      onClick(...args);
    }
  };
  return (
    <button onClick={onClickFinal} className={`${className || 'btn-primary mb-4'} ${disabled ? 'opacity-50' : ''}`} disabled={disabled}>
      {children}
    </button>
  );
};
