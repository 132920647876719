import { RoutePaths } from '../RoutePaths';
import {getHrefWithSameQueryParams} from "../utilities";
import { Link } from 'react-router-dom';

export const NotFound = ({ resetErrorBoundary }) => {
  return (
    <>
      <div className="container mx-auto 2xl:max-w-default flex flex-col items-center text-center px-4 lg:px-8">
        <div className="py-24">
          <h1 className="text-h2 md:text-h1 font-semibold text-neutral_300">
            Oops! Something went wrong.
          </h1>

          <div className="mt-12">
            <Link
              event="website_notFound_backHome_click"
              href={getHrefWithSameQueryParams(RoutePaths.HOME)}
              className="text-p text-primary_500"
            >
              &larr; Back to the home page
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
