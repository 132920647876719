import { useEffect } from 'react';
import Cookies from 'js-cookie';

const isDevelopment = process.env.NODE_ENV === 'development';

const isDebug = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const debugABText = urlParams.get('debugABText');
  return debugABText || window.location.hostname !== 'zcloud.ws';
};

const sendAnalyticsEvent = (contentEnum) => {
  if (!contentEnum.event) {
    console.error(
      'useABText hook: event is required in the enum option to be used'
    );
  }
  if (!contentEnum.eventCategory) {
    console.error(
      'useABText hook: eventCategory is required in the enum option to be used'
    );
  }
  if (!contentEnum.eventLabel) {
    console.error(
      'useABText hook: eventLabel is required in the enum option to be used'
    );
  }
  if (!contentEnum.eventValue) {
    console.error(
      'useABText hook: eventValue is required in the enum option to be used'
    );
  }
  window.gtag('event', contentEnum.event, {
    event_category: contentEnum.eventCategory,
    event_label: contentEnum.eventLabel,
    value: contentEnum.eventValue,
  });
};

const getContentBySearchParamValue = ({ name, enumDefinition, utmContent }) => {
  const cookieName = `ab-text-${name}`;
  const enumName = Cookies.get(cookieName);

  if (enumName && enumDefinition[enumName]) {
    if (isDebug()) {
      console.log('useABText hook: uses cookie', cookieName, enumName);
    }
    return enumDefinition[enumName];
  }

  const arrayOfOptions = Object.values(enumDefinition);

  const enumFields =
    (utmContent &&
      arrayOfOptions.find(({ searchParamValue }) =>
        utmContent.split(',').some((v) => v === searchParamValue)
      )) ||
    arrayOfOptions[Math.floor(Math.random() * arrayOfOptions.length)];

  // 1 day
  Cookies.set(cookieName, enumFields.name, { expires: 1 });

  if (isDebug()) {
    console.log('useABText hook: saves cookie', cookieName, enumFields.name);
  }
  return enumFields;
};

export const useABText = ({ name, enumDefinition }) => {
  if (!name) {
    console.error('useABText hook: name is required');
  }

  const urlParams = new URLSearchParams(window.location.search);
  const utmContent = urlParams.get('utm_content');
  const contentEnum = getContentBySearchParamValue({
    name,
    enumDefinition,
    utmContent,
  });

  if (!contentEnum.text) {
    console.error(
      'useABText hook: text is required in the enum option to be used'
    );
  }

  useEffect(() => {
    if (isDevelopment) {
      return;
    }
    sendAnalyticsEvent(contentEnum);
    if (isDebug()) {
      console.log(
        'useABText hook: sending event to GA',
        name,
        contentEnum.name
      );
    }
  }, []);

  return contentEnum.text;
};
